import { FC } from "react"
import "./styles.scss"
import {useTranslation} from "../../../hooks/useTranslation";

export const ListHeader: FC = () => {
    const { p, t, i18n } = useTranslation('saved_routes');

    return (
    <div className="saved-routes-list-header">
      <div className="saved-routes-list-header__text">{p('route_name')}</div>
      <div className="saved-routes-list-header__text">{p('creating_date')}</div>
      <div className="saved-routes-list-header__text__actions">{p('actions')}</div>
    </div>
  )
}
