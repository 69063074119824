import React, { FC } from 'react';
import { Modal } from '@mui/material';
import './styled.scss';
import checkIcon from '../../../assets/images/CheckIcon.svg';

interface ISuccessModalProps {
    isVisible: boolean,
    setIsVisible: () => void,
    title: string
}

export const SuccessModal: FC<ISuccessModalProps> = ({ isVisible, setIsVisible, title }) => {
    return (
        <Modal
            onClose={setIsVisible}
            sx={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            open={isVisible}>
            <div className='success-modal'>
                <img src={checkIcon} alt={'check-icon'} />
                <h5 className={'success-modal__title'}>{title}</h5>
            </div>
        </Modal>
    )
}