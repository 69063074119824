import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Slide } from '@mui/material';
import logo from '../../../assets/images/logo.png';
import xIcon from '../../../assets/fonts/close.svg';
import List from '@mui/material/List';
import { routes } from '../../../constants/sideBarRoutes';
import { NavLink } from 'react-router-dom';
import { CustomSideTab } from '../SideBar/CustomSideTab/CustomSideTab';
import Typography from '@mui/material/Typography';
import { generalActions } from '../../../backend/store/reducers/generalReducer';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { authTriggerActions } from "../../../backend/store/reducers/authReducer"


export const SideBarMobile = () => {
    const dispatch = useDispatch();
    const logout = () => {
        dispatch(authTriggerActions.logoutUserTriggerAC())
        dispatch(generalActions.setVisibleSideBar(false))
    }
    const isVisible = useSelector((state: any) => state.generalStore.visibleSideBar);
    return (
        <>
            {isVisible && (
                <Slide in={isVisible} direction={'right'}>
                    <Box
                        sx={{
                            width: '80%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            height: '100%',
                            zIndex: 2000,
                            backgroundColor: '#FFFFFF',
                            padding: '10px 20px',
                        }}>

                        <Box sx={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                            <img src={logo} alt="logo" />
                            <img onClick={() => dispatch(generalActions.setVisibleSideBar(false))}
                                src={xIcon}
                                alt="logo" />
                        </Box>
                        <Typography sx={{
                            color: '#6A6A99',
                            fontSize: '11px',
                            marginTop: 3
                        }}>GENERAL</Typography>
                        <Box>
                            <List className="sidebar__ul-general">
                                {routes
                                    .filter((item) => item.group === 'general')
                                    .map((element, index) => (
                                        <NavLink
                                            to={element.link}
                                            key={index}
                                            style={{ textDecoration: 'none' }}
                                            onClick={() => {
                                                dispatch(generalActions.setVisibleSideBar(false))
                                            }}
                                        >
                                            {() => (
                                                <CustomSideTab
                                                    icon={element.icon}
                                                    text={element.route}
                                                    isActive={false}
                                                />
                                            )}
                                        </NavLink>
                                    ))}
                            </List>
                        </Box>
                        <Typography sx={{
                            color: '#6A6A99',
                            fontSize: '11px',
                            marginTop: 3
                        }}>ABOUT US</Typography>
                        <List className="sidebar__ul-about">
                            {routes
                                .filter((item) => item.group === 'about')
                                .map((element, index) => (
                                    <NavLink
                                        to={element.link}
                                        key={index}
                                        style={{ textDecoration: 'none' }}
                                        onClick={() =>
                                            dispatch(generalActions.setVisibleSideBar(false))}
                                    >
                                        {() => (
                                            <CustomSideTab
                                                icon={element.icon}
                                                text={element.route}
                                                isActive={false}
                                            />
                                        )}
                                    </NavLink>
                                ))}
                        </List>
                        <Box sx={{
                            paddingTop: '22px',
                            paddingLeft: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            borderTop: '1px solid #C5CAE3',
                            gap: '25px',
                            marginTop: '30px'
                        }}
                            onClick={() => {
                                logout()
                            }
                            }>
                            <PowerSettingsNewIcon sx={{ color: '#6A6A99' }} />
                            <Typography sx={{
                                fontFamily: 'Rubik, sans-serif',
                                letterSpacing: '0.2px',
                                opacity: '0.6',
                                fontWeight: '400'
                            }}>
                                Go out
                            </Typography>
                        </Box>
                    </Box>
                </Slide >
            )}
        </>
    );
};