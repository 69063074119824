import * as React from "react"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Badge from "@mui/material/Badge"
import MenuItem from "@mui/material/MenuItem"
import Menu from "@mui/material/Menu"
import AccountCircle from "@mui/icons-material/AccountCircle"
import NotificationsIcon from "@mui/icons-material/Notifications"
import MoreIcon from "@mui/icons-material/MoreVert"
import bellFont from "../../../assets/fonts/bell.svg"
import userFont from "../../../assets/fonts/user.svg"
import SubjectIcon from '@mui/icons-material/Subject';
import "./AppBar.scss"
import { useDispatch, useSelector } from "react-redux"
import { authTriggerActions } from "../../../backend/store/reducers/authReducer"
import { useNavigate } from "react-router-dom"
import { Notifications } from "../Notifications"
import { actions, notificationsTriggerActions } from '../../../backend/store/reducers/notificationsReducer';
import logo from '../../../assets/images/logo.png';
import logoSmall from '../../../assets/images/logo-small.svg';
import {generalActions} from '../../../backend/store/reducers/generalReducer';
import { useTranslation } from "../../../hooks/useTranslation"

export default function AppBarComponent() {  
  const { p } = useTranslation('general');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isUser = localStorage.getItem("user")

  React.useEffect(() => {
    dispatch(notificationsTriggerActions.getNotificationsCountTriggerAC());
  }, []);

  
  const storedNotificationsCount = useSelector((state: any) => state.notificationsStore.notificationsCount);
  const openNotifications = useSelector((state: any) => state.notificationsStore.isVisibleNotificationModal);
  const notificationsCount = React.useMemo(() => storedNotificationsCount || null, [storedNotificationsCount]);
  
  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const logout = () => {
    dispatch(authTriggerActions.logoutUserTriggerAC())
  }

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMenuClose = (callback?: () => void) => {
    if (callback) {
      callback()
    }
    setAnchorEl(null)
    handleMobileMenuClose()
  }

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const handleToggleNotifications = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    dispatch(actions.setVisibilityNotificationModal(true))
  };

  const menuId = "primary-search-account-menu"
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={() => handleMenuClose()}
      sx={{
        display: { md: "block" }
      }}
    >
      {/*<MenuItem onClick={() => handleMenuClose()}>Profile</MenuItem>*/}
      <MenuItem onClick={() => handleMenuClose(logout)}>{p('logout')}</MenuItem>
    </Menu>
  )

  const mobileMenuId = "primary-search-account-menu-mobile"
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={notificationsCount} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>{p('logout')}</p>
      </MenuItem>
    </Menu>
  )

  if (!isUser) {
    navigate("/login")
  }

  return (
      <Box className="appbar" sx={{height: { sx: 56, md: 77 }}}>
        <AppBar position="relative" sx={{height: { sx: 56, md: 77 }}}>
          <Toolbar sx={{paddingLeft: { md: "69px" }}}>
            <Box sx={{display: {xs: 'none', md: 'block'}, paddingTop: { md: "11px" }}}>
              <img src={logo} alt="logo" />
            </Box>
            <Box sx={{display: {xs: 'block', md: 'none'}}}>
              <img src={logoSmall} alt="logo"/>
            </Box>
            <Box sx={{flexGrow: 1}}/>
            <Box>
              <IconButton
                  size="large"
                  aria-label="show 17 new notifications"
                  color="inherit"
                  onClickCapture={handleToggleNotifications}
              >
                <Badge badgeContent={notificationsCount} color="error">
                  <img src={bellFont} alt="bell"/>
                </Badge>
              </IconButton>
              <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
              >
                <img src={userFont} alt="bell"/>
              </IconButton>
            </Box>
            <Box>
              <Box sx={{display: {xs: 'flex', md: 'none'}, marginLeft: { xs: 2, md: 0 }}}>
                <IconButton
                    size="large"
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={() => dispatch(generalActions.setVisibleSideBar(true))}
                    color="inherit"
                >
                  <SubjectIcon style={{color: '#6A6A99'}}/>
                </IconButton>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        {/*{renderMobileMenu}*/}
        {renderMenu}
        {openNotifications && <Notifications/>}
      </Box>
  );
}
